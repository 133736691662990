html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0;
  font-family: "AvertaRegular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 60px; /* Margin bottom by footer height */
}
h1,
h2 {
  font-family: "AvertaLight", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "AvertaLight";
  src: url("./assets/fonts/avertaLight.woff2") format("woff2"),
    url("./assets/fonts/avertaLight.woff") format("woff"),
    url("./assets/fonts/avertaLight.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvertaRegular";
  src: url("./assets/fonts/averta.woff2") format("woff2"),
    url("./assets/fonts/averta.woff") format("woff"),
    url("./assets/fonts/averta.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
