.App {
  color: #6b6b6b;
  overflow-x: hidden;
}

.App-link {
  color: #61dafb;
}

//Grid
.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 1100px;
}

.navbar {
  padding: 0.5rem 0;
}

.hero__text-container {
  margin-top: 11rem;
  .title {
    font-size: 1.3rem;
  }
  .tagline {
    font-family: "AvertaLight", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;

    line-height: 1.5em;
    letter-spacing: 0.03em;
  }
}
.hero__image {
  max-height: 38rem;
  margin-right: -4rem;
  margin-top: 4rem;
}

.gradient1 {
  display: inline-block;
  position: relative;
  color: #000;
  background: #fff;
  mix-blend-mode: multiply;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#6435c9),
      to(#03e5be)
    );
    background: -webkit-linear-gradient(left, #6435c9, #00b5ad);
    background: -o-linear-gradient(left, #6435c9, #00b5ad);
    background: linear-gradient(to right, #6435c9, #00b5ad);
    pointer-events: none;
    mix-blend-mode: screen;
  }
}

.gradient-sec {
  background: #a072ff;
  background: -moz-linear-gradient(-45deg, #a072ff 0%, #03e5be 100%);
  background: -webkit-linear-gradient(-45deg, #a072ff 0%, #03e5be 100%);
  background: -webkit-linear-gradient(315deg, #a072ff 0%, #03e5be 100%);
  background: -o-linear-gradient(315deg, #a072ff 0%, #03e5be 100%);
  background: linear-gradient(135deg, #a072ff 0%, #03e5be 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a072ff', endColorstr='#03e5be',GradientType=1 );
  padding: 5em 0;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    letter-spacing: 0.08em;
  }
  color: white;
  font-family: "AvertaLight", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

.logo_text {
  font-family: "AvertaRegular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

.dark-gray {
  color: #6b6b6b;
}

.high-light-sec {
  padding: 4rem 2rem;
}

.logo-container {
  height: 1.8rem;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.project {
  // background: #f6f6f6;
  .project__image {
    // box-shadow: 1px 1px 5px black;
    box-shadow: 1px 1px 8px rgba(182, 182, 182, 0.7);
  }
  .sub-title {
    font-size: 1rem;
  }
  .tag-container {
    .tag {
      background: #eceff6;
      padding: 0.5em 1em;
      border-radius: 5em;
      color: #6b6b6b;
      margin-bottom: 1em;
      display: inline-block;
    }
  }
}

.bold {
  font-weight: bold;
}

.contactSection {
  padding: 2rem 0;
}
@media only screen and (min-width: 768px) {
  .hero__text-container {
    margin-top: 11rem;
  }
  .container.hero {
    min-height: 43rem;
  }
}

@media only screen and (max-width: 768px) {
  .hero__text-container {
    margin-top: 4rem;
  }
  .hero__image {
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 576px) {
  .logo-container {
    &.r-2 {
      margin-top: 3rem;
    }
  }
}
